import { useQuery } from "react-query";
import apiFetch from "../api";

const Podcast = ({ id }) => {
  const { data: download } = useQuery(["dowload", "news", id], async () => {
    return await apiFetch(`/api/podcasts/${id}/download/`);
  }, {});
  return download?.url && (
    <audio controls style={{ width: "100%" }}>
      <source src={`${download.url}`} type="audio/mpeg" />
    </audio>
  );
}

export default Podcast;