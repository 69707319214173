import { useEffect } from "react"

export const useDocument = ({ title }) => {
  useEffect(() => {
    let newTitle = "Daily News Bite";
    if (title) {
      newTitle = `${title} | ${newTitle}`;
    }
    document.title = newTitle;
  }, [title]);
  return { title: document.title };
};
