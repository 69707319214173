import React from 'react';
import { HiHandThumbUp, HiHandThumbDown } from 'react-icons/hi2';
import { useMutation, useQueryClient } from 'react-query';
import apiFetch from '../api';
import TextBtn from './buttons/TextBtn';
import formatDate from '../utils/formatDate';

const ArticleCard = ({ article }) => {
  const queryClient = useQueryClient();

  const likeMutation = useMutation(
    () => apiFetch(`/api/articles/${article.id}/like/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('articles');
      },
    }
  );

  const dislikeMutation = useMutation(
    () => apiFetch(`/api/articles/${article.id}/dislike/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('articles');
      },
    }
  );

  const readMutation = useMutation(
    () => apiFetch(`/api/articles/${article.id}/read/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('articles');
      },
    }
  );

  const handleLike = async () => {
    try {
      await likeMutation.mutateAsync();
    } catch (error) {
      console.error('Error liking article:', error);
    }
  };

  const handleDislike = async () => {
    try {
      await dislikeMutation.mutateAsync();
    } catch (error) {
      console.error('Error disliking article:', error);
    }
  };

  const handleRead = async () => {
    try {
      await readMutation.mutateAsync();
    }
    catch (error) {
      console.error('Error reading article:', error);
    }
  };


  const formatNumber = (num) => {
    if (num > 999) {
      return `${(num / 1000).toFixed(1)}k`;
    }
    return num;
  };

  return (
    <div className="card mt-3 max-w-screen-xl d">
      <div className='flex flex-row gap-4'>
        <div className={`flex flex-col ${article.image ? 'basis-3/4' : ''}`}>
          <h4><a href={article.url} target="_blank" rel="noreferrer" onClick={() => {
            handleRead();
          }}>{article.title}</a></h4>
          <span className="byline">
            {formatDate(article.date)}
          </span>
          <div className="grow hidden md:block">
            <p>{article.description}</p>
          </div>
        </div>
        {article.image && (<div className="basis-1/4">
          <img className="rounded" src={article.image} alt={article?.title} /></div>
        )}
      </div>
      <div className="mt-4 md:hidden">
        <p>{article.description}</p>
      </div>
      <div className="flex justify-between mt-4">
        <div className="text-sm text-gray-500">
            <img className="h-5 w-5 mr-2 inline-block" src={`https://www.google.com/s2/favicons?domain=${article.domain}`} alt={article.domain} />
            <a href={article.url}>{article.domain}</a>
        </div>
        <div className="flex justify-end gap-2">
          <div className='flex align-middle text-gray/75'>
            <TextBtn onClick={handleLike} active={article?.user_article?.like} >
              <HiHandThumbUp />
            </TextBtn>
            {article.likes > 0 && (
              <span>{formatNumber(article.likes)}</span>
            )}
          </div>
          <div className='flex align-middle text-gray/75'>
            <TextBtn onClick={handleDislike} active={article?.user_article?.like === false} >
              <HiHandThumbDown />
            </TextBtn>
            {article.dislikes > 0 && (
              <span>{formatNumber(article.dislikes)}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleCard;
