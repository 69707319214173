import React from 'react';
import { HiHandThumbUp, HiHandThumbDown } from 'react-icons/hi2';
import { useMutation, useQueryClient } from 'react-query';
import apiFetch from '../api';
import TextBtn from './buttons/TextBtn';
import { Link } from 'react-router-dom';
import formatDate from '../utils/formatDate';

const StoryCard = ({ story }) => {
  const queryClient = useQueryClient();

  const likeMutation = useMutation(
    () => apiFetch(`/api/stories/${story.id}/like/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stories');
      },
    }
  );

  const dislikeMutation = useMutation(
    () => apiFetch(`/api/stories/${story.id}/dislike/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stories');
      },
    }
  );

  const readMutation = useMutation(
    () => apiFetch(`/api/stories/${story.id}/read/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stories');
      },
    }
  );

  const handleLike = async () => {
    try {
      await likeMutation.mutateAsync();
    } catch (error) {
      console.error('Error liking story:', error);
    }
  };

  const handleDislike = async () => {
    try {
      await dislikeMutation.mutateAsync();
    } catch (error) {
      console.error('Error disliking story:', error);
    }
  };

  const handleRead = async () => {
    try {
      await readMutation.mutateAsync();
    }
    catch (error) {
      console.error('Error reading story:', error);
    }
  };


  const formatNumber = (num) => {
    if (num > 999) {
      return `${(num / 1000).toFixed(1)}k`;
    }
    return num;
  };

  const image = story?.articles?.[0]?.image;

  return (
    <div className="card mt-3 max-w-screen-xl d">
      <div className='flex flex-row gap-4'>
        <div className={`flex flex-col ${image ? 'basis-3/4' : ''}`}>
          <h4><Link to={`/news/stories/${story.id}`}>{story.title}</Link></h4>
          <span className="byline">{formatDate(story?.date)}</span>
          <div className="grow hidden md:block">
            <p>{story?.description}</p>
          </div>
        </div>
        {image && (<div className="basis-1/4">
          <img className="rounded" src={image} alt={story?.title} /></div>
        )}
      </div>
      <div className="mt-4 md:hidden">
        <p>{story?.description}</p>
      </div>
      <div className="flex justify-between mt-4">
        <div className='text-gray-800 font-italic' >
          {story.articles?.map((article, index) => (
            <img title={article.domain} key={article.domain} className="ml-1 h-5 w-5 inline-block" src={`https://www.google.com/s2/favicons?domain=${article.domain}`} alt={article.domain} />
          ))}
        </div>
        <div className="flex justify-end gap-2">
          <div className='flex align-middle text-gray/75'>
            <TextBtn onClick={handleLike} active={story?.user_story?.like} >
              <HiHandThumbUp />
            </TextBtn>
            {story.likes > 0 && (
              <span>{formatNumber(story.likes)}</span>
            )}
          </div>
          <div className='flex align-middle text-gray/75'>
            <TextBtn onClick={handleDislike} active={story?.user_story?.like === false} >
              <HiHandThumbDown />
            </TextBtn>
            {story.dislikes > 0 && (
              <span>{formatNumber(story.dislikes)}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoryCard;
