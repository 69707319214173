import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import apiFetch from "../api";
import ArticleCard from "../Components/ArticleCard";
import { HiHandThumbDown, HiHandThumbUp } from "react-icons/hi2";
import TextBtn from "../Components/buttons/TextBtn";
import Tabs from "../Components/Tabs";
import { useState } from "react";
import { useDocument } from "../hooks/useDocument";
import formatDate from "../utils/formatDate";

const Story = () => {
  const {storyId } = useParams();
  const {data: story} = useQuery(["stories", storyId], async () => {
    return await apiFetch(`/api/stories/${storyId}/`);
  });
  const [tab, setTab] = useState("articles");
  const queryClient = useQueryClient();

  useDocument({ title: story?.title || "Story" });

  const likeMutation = useMutation(
    () => apiFetch(`/api/stories/${story.id}/like/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stories');
      },
    }
  );

  const dislikeMutation = useMutation(
    () => apiFetch(`/api/stories/${story.id}/dislike/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stories');
      },
    }
  );

  const readMutation = useMutation(
    () => apiFetch(`/api/stories/${story.id}/read/`, { method: 'POST' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stories');
      },
    }
  );

  const handleLike = async () => {
    try {
      await likeMutation.mutateAsync();
    } catch (error) {
      console.error('Error liking story:', error);
    }
  };

  const handleDislike = async () => {
    try {
      await dislikeMutation.mutateAsync();
    } catch (error) {
      console.error('Error disliking story:', error);
    }
  };

  const handleRead = async () => {
    try {
      await readMutation.mutateAsync();
    }
    catch (error) {
      console.error('Error reading story:', error);
    }
  };


  const formatNumber = (num) => {
    if (num > 999) {
      return `${(num / 1000).toFixed(1)}k`;
    }
    return num;
  };
  const image = story?.articles?.[0]?.image;
  return (
    <div className="m-auto max-w-4xl">
      <div className="flex flex-row gap-4">
        <div className={image ? 'basis-3/4' : ''}>
          <h1 className="text-xxl">{story?.title}</h1>
          <span className="byline">{formatDate(story?.date)}</span>
          <div className="hidden md:block">
            <p>{story?.description}</p>
          </div>
        </div>
        {image && (<div className="basis-1/4">
          <img className="rounded" src={image} alt={story?.title} /></div>
        )}
      </div>
      <div className="md:hidden">
        <p>{story?.description}</p>
      </div>
      <div className="flex justify-end mt-4 gap-2">
        <div className='flex align-middle text-gray/75'>
          <TextBtn onClick={handleLike} active={story?.user_story?.like} >
            <HiHandThumbUp />
          </TextBtn>
          {story?.likes > 0 && (
            <span>{formatNumber(story?.likes)}</span>
          )}
        </div>
        <div className='flex align-middle text-gray/75'>
          <TextBtn onClick={handleDislike} active={story?.user_story?.like === false} >
            <HiHandThumbDown />
          </TextBtn>
          {story?.dislikes > 0 && (
            <span>{formatNumber(story?.dislikes)}</span>
          )}
        </div>
      </div>
      <Tabs tabs={[
        { id: "articles", name: "Articles" },
        { id: "content", name: "Claims" },
      ]} activeTab={tab} setActiveTab={setTab} />
      <div className="h-[calc(100vh-200px)] flex flex-col">
        <div className="grow p-2 w-100 overflow-auto">
          {tab === "articles" && (
            <div className="grid gap-4 grid-cols-1">
              {story?.articles && story?.articles?.map(p => (
                <ArticleCard key={p.id} article={p} />
              ))}
            </div>
          )}
          {tab === "content" && (
            <ul className="list-disc list-outside pl-5">
              {story?.content && story?.content?.map(p => (
                <li key={p} className="mt-4" >{p}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Story;

