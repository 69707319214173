import { useMutation, useQuery, useQueryClient } from "react-query";
import apiFetch from "../api";
import { Controller, useForm } from "react-hook-form";
import { useDocument } from "../hooks/useDocument";
import Input from "../Components/Input";


const FeedsInput = ({ feeds, value, onChange }) => {
  const selectedFeedIds = value.map(v => v.id); 
  return (
    <>
      {feeds.map(feed => (
        <div key={feed.id}>
          <input className="accent-primary-400" type="checkbox" value={feed.id} checked={selectedFeedIds?.includes(feed.id) ?? false} onChange={(e) => {
            if (e.target.checked) {
              onChange([...value, feed]);
            } else {
              onChange(value.filter(v => v.id !== feed.id));
            }
          }} />
          <label className="ml-3">{feed.title}</label>
        </div>
      ))}
    </>
  )
}


const UserForm = ({ user, feeds }) => {
  const form = useForm({
    defaultValues: {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      userprofile: {
        description: user.userprofile?.description,
        feeds: user.userprofile?.feeds || []
      }
    }
  });

  const queryClient = useQueryClient();

  const updateUser = useMutation(async (data) => {
    data.userprofile.feed_ids = data.userprofile.feeds.map(feed => feed.id);
    data.userprofile.feeds = undefined;
    return await apiFetch(`/api/users/me/`, {
      method: 'PATCH',
      body: JSON.stringify(data)
    });
  });

  const onSubmit = async (data) => {
    await updateUser.mutateAsync(data);
    queryClient.invalidateQueries(['users', 'me']);
  }

  return (
    <form className="flex flex-col gap-2" onSubmit={form.handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <Input type="email" {...form.register('email')} />
      </div>
      <div className="form-group">
        <label htmlFor="first_name">First Name</label>
        <Input type="text" {...form.register('first_name')} />
      </div>
      <div className="form-group">
        <label htmlFor="last_name">Last Name</label>
        <Input type="text" {...form.register('last_name')} />
      </div>
      <div className="form-group">
        <label htmlFor="description">Interests</label>
        <Input type="text" {...form.register('userprofile.description')} />
      </div>
      <div className="form-group">
        <label htmlFor="feeds">Feeds</label>
        <Controller
          name="userprofile.feeds"
          control={form.control}
          render={({ field }) => (
            <FeedsInput feeds={feeds} value={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="text-right">
        <button type="submit" className="btn btn-primary" disabled={updateUser.isLoading}>Save</button>
      </div>
    </form>
  );

};

const Users = () => {
  const { data: userData } = useQuery(['users', 'me'], async () => await apiFetch(`/api/users/me/`));
  useDocument(userData?.first_name ? `${userData.first_name} ${userData.last_name}` : 'Profile');
  const {data: feeds} = useQuery(["feeds"], async () => {
    return await apiFetch("/api/feeds/");
  }, { keepPreviousData: true });
  return (
    <div className="m-auto max-w-4xl">
      <h1>Profile</h1>
      {userData && feeds && <UserForm user={userData} feeds={feeds?.results} />}
    </div>
  )
};

export default Users;
