import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from './Views/Home';
import Users from './Views/Users';
import Layout from './Views/Layout';
import Login from './Views/Login';
import Deals from './Views/Deals';
import ChatList from './Views/ChatList';
import Chat from './Views/Chat';
import Canvas from './Views/Canvas';
import CanvasList from './Views/CanvasList';
import News from './Views/News';
import NewsItem from './Views/NewsItem';
import NewsArchive from './Views/NewArchive';
import Story from './Views/Story';
import { useDocument } from './hooks/useDocument';


const GOOGLE_CLIENT_ID = '1055949537719-3sarcsn5c61huqfep7s9vvd7q1g665a8.apps.googleusercontent.com';
  
const queryClient = new QueryClient();

function App() {
  useDocument({});
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route path="/deals" element={<Deals />} />
              <Route path="/canvas">
                <Route index element={<CanvasList />} />
                <Route path="new" element={<Canvas />} />
                <Route path=":canvasId" element={<Canvas />} />
              </Route>
              <Route path="/chats">
                <Route index element={<ChatList />} />
                <Route path=":chatId" element={<Chat />} />
              </Route>
              <Route path="/news" >
                <Route index element={<News />} />
                <Route path="archive" element={<NewsArchive />} />
                <Route path="feeds/:feedId" element={<News />} />
                <Route path="stories/:storyId" element={<Story />} />
                <Route path=":newsId" element={<NewsItem />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
